var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1adc1b10376f388a3d246d7895a6da87d130f993"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  enabled: process.env.NODE_ENV !== "development",
  ignoreErrors: [
    "document.getElementsByClassName.ToString",
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
    "Unexpected token <",
    "expected expression, got <",
    /**
     * The error: "Cannot read properties of undefined (reading '_leaflet_pos')"
     * is a known issue in react-leaflet, as documented here:
     * https://github.com/PaulLeCam/react-leaflet/issues?q=Cannot%20read%20properties%20of%20undefined%20(reading%20%27_leaflet_pos%27)
     *
     * This error typically occurs when dynamically adding or removing map components,
     * leading to inconsistencies in Leaflet's internal state.
     *
     * Since upgrading to react-leaflet v5 is not an option yet because (it requires React 19 which may break other implementations),
     * and the suggested workaround does not resolve the issue
     * (https://github.com/PaulLeCam/react-leaflet/issues/340), we have opted to
     * ignore this error in Sentry to prevent unnecessary alerts.
     */
    "TypeError: Cannot read properties of undefined (reading '_leaflet_pos')",
  ],
  denyUrls: [/maps\.googleapis\.com/, /maps\.google\.com/, /js\.intercomcdn\.com/],
});
